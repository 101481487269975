import cn from 'classnames'
import s from './styles.module.css'
import FsaBadge from 'components/payment/common/fsa-badge'
import BulletPoints from 'components/payment/common/bullet-points'
import { DESKTOP_WIDTH } from 'constants/breakpoints'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { isServer } from 'utilities/environment'
import useIsFirstRender from 'hooks/use-is-first-render'
import DesktopArrow from './desktop-arrow'
import MobileArrow from './mobile-arrow'
import { useIsPaidTrial } from 'hooks/use-is-paid-trial'

const LandingPagePlanCard = ({
  dailyPriceWithoutCoupon,
  pricingDescription,
}) => {
  const {
    paidTrialAmount,
    isPaidTrial,
    split: paidTrialSplit,
  } = useIsPaidTrial()
  const isFirstRender = useIsFirstRender()
  const isDesktop = useWindowSizeCondition(({ width }) => width > DESKTOP_WIDTH)

  const dailyPriceWithoutCouponHasDecimals = dailyPriceWithoutCoupon % 1 !== 0
  const dailyPriceWithoutCouponDescription =
    '$' +
    (dailyPriceWithoutCouponHasDecimals
      ? dailyPriceWithoutCoupon.toFixed(2)
      : dailyPriceWithoutCoupon)

  if (isDesktop || isServer() || isFirstRender) {
    return (
      <div
        className={cn(s.desktopContainer, {
          [s.lessTopMargin]: isPaidTrial,
        })}>
        <div className={cn(s.landingPagePlanContainer, s.programCard)}>
          <div className={s.landingPageDesktopFsaBadgeContainer}>
            <span>FSA/HSA Eligible</span>
          </div>
          <div className={s.landingPagePlanHeaderContainer}>
            <div className={s.landingPageTitleHeader}>
              <span className={s.title}>
                {isPaidTrial ? 'Your Plan Includes' : 'Kickoff Program'}
              </span>
              <div className={s.fsaBadgeContainer}>
                <FsaBadge />
              </div>
            </div>
          </div>
          <div className={s.landingPagePlanBodyContainer}>
            <div
              className={cn(s.landingPageBulletPointsSection1, {
                [s.noBorder]: isPaidTrial,
              })}>
              <BulletPoints
                productId={'LANDING_PAGE_SECTION_1'}
                className={s.ladingPageBulletPoints}
                checkStyle={s.ladingPageBulletPointsCheck}
                checkHeight={9}
                checkWidth={9}
                textStyle={s.landingPageBulletPointsSectionText}
                isPaidTrial={isPaidTrial}
              />
            </div>
            {!isPaidTrial && (
              <div className={s.landingPageBulletPointsSection2}>
                <BulletPoints
                  productId={'LANDING_PAGE_SECTION_2'}
                  className={s.ladingPageBulletPoints}
                  checkStyle={s.ladingPageBulletPointsCheck}
                  checkHeight={9}
                  checkWidth={9}
                  textStyle={s.landingPageBulletPointsSectionText}
                  isPaidTrial={isPaidTrial}
                />
              </div>
            )}
          </div>
        </div>
        <div className={s.landingPagePlanContainer}>
          {!isPaidTrial && (
            <div className={s.fyiCallout}>
              FYI: Conventional in-person trainers and dietitians charge
              $100/Session!
              <DesktopArrow className={s.desktopArrow} />
            </div>
          )}
          <span className={s.title}>Pricing</span>
          <div className={s.landingPagePriceHeader}>
            <span
              className={cn(s.landingPageDailyPrice, {
                [s.vert]: paidTrialSplit === 'B',
              })}>
              <span className={s.price}>
                {paidTrialSplit === 'B'
                  ? `$${paidTrialAmount}`
                  : paidTrialSplit === 'C'
                  ? '$3'
                  : dailyPriceWithoutCouponDescription}
              </span>
              {paidTrialSplit === 'B' ? (
                <span>for 7 days</span>
              ) : (
                <span>/day</span>
              )}
            </span>
          </div>
          <span className={s.landingPagePricingDescription}>
            {pricingDescription}
          </span>
        </div>
      </div>
    )
  }

  if (isPaidTrial) {
    return (
      <div className={s.mobileContainer}>
        <div className={cn(s.landingPagePlanContainer, s.trialPlanContainer)}>
          <div className={s.landingPagePlanHeaderContainer}>
            <div className={s.landingPageTitleHeader}>
              <span className={s.title}>Pricing</span>
              <div className={s.fsaBadgeContainer}>
                <FsaBadge />
              </div>
            </div>
            <div className={s.landingPagePriceHeader}>
              <span className={s.landingPageDailyPrice}>
                <span>
                  {paidTrialSplit === 'B' ? `$${paidTrialAmount}` : '$3/day'}
                </span>
                {paidTrialSplit === 'B' && <span> for 7 days</span>}
              </span>
            </div>
            <span className={cn(s.landingPagePricingDescription, s.semiBlack)}>
              {pricingDescription}
            </span>
          </div>
        </div>
        <div className={cn(s.landingPagePlanContainer, s.trialPlanContainer)}>
          <div className={cn(s.title, s.trialTitle)}>Your Plan Includes</div>
          <BulletPoints
            productId={'LANDING_PAGE_SECTION_2'}
            className={s.ladingPageBulletPoints}
            checkStyle={s.ladingPageBulletPointsCheck}
            checkHeight={9}
            checkWidth={9}
            textStyle={s.landingPageBulletPointsSectionTextTrial}
            isPaidTrial={isPaidTrial}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={s.mobileContainer}>
      <div className={s.fyiCallout}>
        <MobileArrow className={s.arrow} />
        FYI: Conventional dietitians and in-person trainers charge
        $100+/Session!
      </div>
      <div className={s.landingPagePlanContainer}>
        <div className={s.landingPagePlanHeaderContainer}>
          <div className={s.landingPageTitleHeader}>
            <span className={s.title}>Kickoff Program</span>
            <div className={s.fsaBadgeContainer}>
              <FsaBadge />
            </div>
          </div>
          <div className={s.landingPagePriceHeader}>
            <span className={s.landingPageDailyPrice}>
              <span>{dailyPriceWithoutCouponDescription}</span>
              <span>/day</span>
            </span>
            <span className={s.landingPagePricingDescription}>
              {pricingDescription}
            </span>
          </div>
        </div>
        <div className={s.landingPagePlanBodyContainer}>
          <div className={s.landingPageBulletPointsSection1}>
            <BulletPoints
              productId={'LANDING_PAGE_SECTION_1'}
              className={s.ladingPageBulletPoints}
              checkStyle={s.ladingPageBulletPointsCheck}
              checkHeight={9}
              checkWidth={9}
              textStyle={s.landingPageBulletPointsSectionText}
              isPaidTrial={isPaidTrial}
            />
          </div>
          <div className={s.landingPageBulletPointsSection2}>
            <BulletPoints
              productId={'LANDING_PAGE_SECTION_2'}
              className={s.ladingPageBulletPoints}
              checkStyle={s.ladingPageBulletPointsCheck}
              checkHeight={9}
              checkWidth={9}
              textStyle={s.landingPageBulletPointsSectionText}
              isPaidTrial={isPaidTrial}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPagePlanCard
